import { Dispatch } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { fetchCmsApiAuthor } from 'modules/cms/api/cmsApiAuthorEndpoints';
import { cmsApiFetchTag } from 'modules/cms/api/cmsApiTagEndpoints';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { arrToObject } from 'utils/functional';
import { getJson } from 'utils/http/getJson';

import {
  DeselectFilterOption,
  DynamicFacet,
  MainStorePostSearchState,
  PostSearchFacetKeys,
  ResetFiltersAction,
  SelectAllFilterOption,
  SelectFilterOption,
  SetSearchFilter,
} from './postSearch';

// Utility operations on state

export function resultsFn(state: MainStorePostSearchState) {
  return state.resultsByPage[state.pageIndex.toString()];
}

export function setPostSearchQuery(query: string, didUserChange: boolean) {
  return (dispatch: Dispatch) => {
    if (didUserChange) {
      trackEvent('Articles: Changed Search Query', {
        new_query: query, // TODO: add tracking data for POST
      });
    }

    dispatch({
      type: 'POST_SEARCH_SET_QUERY',
      query,
    });
  };
}

export function loadDynamicFacetOptions({
  authorSlug,
  subsiteId,
  tagsSlug,
}: {
  authorSlug?: string;
  subsiteId: string;
  tagsSlug?: string;
}) {
  return (
    dispatch: Dispatch,
    getState: () => { postSearch: MainStorePostSearchState },
  ) => {
    const state = getState();
    const facetsThatArentLoaded = state.postSearch.facetsThatArentLoaded.filter(
      (facet) =>
        (facet === 'articleTagsFacet' && tagsSlug) ||
        (facet === 'articleAuthorFacet' && authorSlug) ||
        facet === 'articleCategoriesFacet',
    );
    const dynamicFacets: DynamicFacet[] = [
      {
        fetchFn: () =>
          getJson(
            `/data/website/subsites/${subsiteId}/categories?lang=${CURRENT_LOCALE}`,
          ),
        facetKey: 'articleCategoriesFacet',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseToOptions: ({ categories }: any) => categories,
      },
    ];

    if (authorSlug) {
      dynamicFacets.push({
        fetchFn: () => fetchCmsApiAuthor(authorSlug || ''),
        facetKey: 'articleAuthorFacet',
        responseToOptions: (author) => [author],
      });
    }

    if (tagsSlug) {
      dynamicFacets.push({
        fetchFn: () => cmsApiFetchTag(tagsSlug || '', subsiteId),
        facetKey: 'articleTagsFacet',
        responseToOptions: (tag) => [tag],
      });
    }

    return Promise.all(
      dynamicFacets.map(({ facetKey, fetchFn, responseToOptions }) => {
        if (!facetsThatArentLoaded.includes(facetKey)) {
          return Promise.resolve();
        }

        return fetchFn()
          .then((response) => responseToOptions(response))
          .then((options) => {
            dispatch({
              type: 'POST_SEARCH_SET_FACET_OPTIONS',
              facetKey,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              options: options.map((f: any) => f.slug),
              optionTitles: arrToObject(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                options.map(({ slug, name }: any) => [slug, name]),
              ),
              optionsArray: options.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ({ slug, id, icon, parentId, name }: any) => ({
                  id,
                  name,
                  icon,
                  slug,
                  parentId,
                }),
              ),
            });
          });
      }),
    );
  };
}

export function initializePostSearch({
  queryParams,
  subsiteId,
}: {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: Record<string, any>;
  subsiteId: string;
}) {
  return (dispatch: Dispatch) =>
    Promise.all([
      dispatch({
        type: 'POST_SEARCH_INITIALIZE',
        queryParams,
      }),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch<any>(
        loadDynamicFacetOptions({
          subsiteId,
          authorSlug: queryParams['author.slug'],
          tagsSlug: queryParams['tags.slug'],
        }),
      ),
    ]);
}

export function uninitializePostSearch() {
  return (dispatch: Dispatch) =>
    dispatch({
      type: 'POST_SEARCH_UNINITIALIZE',
    });
}

export function setPostSearchPageIndex(
  pageIndex: number,
  didUserChange: boolean,
) {
  return (dispatch: Dispatch) => {
    if (didUserChange) {
      trackEvent('POST: Changed Search Page Number', {
        new_page: pageIndex, // TODO: tracking data
      });
    }

    dispatch({
      type: 'POST_SEARCH_SET_PAGE_INDEX',
      pageIndex,
    });
  };
}

export function setPostSearchFilter(
  filterKey: string,
  value: string | null | undefined,
): SetSearchFilter {
  if (filterKey === 'type') {
    invariant(value, 'type can never be set to undefined/null');
  }

  return {
    type: 'POST_SEARCH_SET_FILTER',
    key: filterKey,
    value,
  };
}

export function selectPostSearchFilterOption(
  filterKey: string,
  option: string | null | undefined,
): SelectFilterOption {
  return {
    type: 'POST_SEARCH_SELECT_FILTER_OPTION',
    key: filterKey,
    option,
  };
}

export function deselectPostSearchFilterOption(
  filterKey: string,
  option: string | null | undefined,
): DeselectFilterOption {
  return {
    type: 'POST_SEARCH_DESELECT_FILTER_OPTION',
    key: filterKey,
    option,
  };
}

export function selectAllPostSearchFilterOptions(
  filterKey: PostSearchFacetKeys,
): SelectAllFilterOption {
  return {
    type: 'POST_SEARCH_SELECT_ALL_FILTER_OPTIONS',
    key: filterKey,
  };
}

export function resetPostSearchFilters(): ResetFiltersAction {
  return {
    type: 'POST_SEARCH_RESET_FILTERS',
  };
}
