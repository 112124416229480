import { getJson } from 'utils/http/getJson';

import { CmsApiTag } from './types/CmsApiTag';

export async function cmsApiFetchTag(
  slug: string,
  subsiteId: string,
): Promise<CmsApiTag> {
  const { tag } = await getJson<{ tag: CmsApiTag }>(
    `/data/website/subsites/${subsiteId}/tags/${slug}`,
  );

  return tag;
}
