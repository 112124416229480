import { getJson } from 'utils/http/getJson';

import { CmsApiAuthor } from './types/CmsApiAuthor';

export async function fetchCmsApiAuthor(slug: string): Promise<CmsApiAuthor> {
  const { author } = await getJson<{ author: CmsApiAuthor }>(
    `/data/website/authors/${slug}`,
  );

  return author;
}
