import { Component } from 'react';
import { connect } from 'react-redux';

import { PostSearchFormUsageContext } from 'modules/postSearch/components/PostSearchFormUsageContext';
import { PostSearchInput } from 'modules/postSearch/components/PostSearchInput';
import {
  resetPostSearchFilters,
  setPostSearchQuery,
} from 'store/postSearch/postSearch.actions';
import { filterObject, isEmpty, mapObject } from 'utils/functional';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';
import { RouteComponentProps, withRouter } from 'utils/withRouter';

type InitialProps = {
  homePageUrl: string;
  searchPlaceholderText?: string;
  usageContext: PostSearchFormUsageContext;
};

type OwnProps = InitialProps & RouteComponentProps;

type Props = OwnProps & {
  query: string;
  resetSearchFilters: typeof resetPostSearchFilters;
  setSearchQuery: typeof setPostSearchQuery;
};
type State = {
  query: string;
  prevProps: {
    query: string;
  };
};

const toQueryParamKey = (k: string) => {
  const mapping = {
    query: 'pq',
  };
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return mapping[k] || k;
};

const makeQueryString = (props: { type?: string; query?: string }) => {
  const search = Object.values(
    mapObject(
      // @ts-expect-error TS(2345): Argument of type '{ type?: string | undefined; que... Remove this comment to see the full error message
      filterObject(props, (k) => !isEmpty(k)),
      // @ts-expect-error TS(2345): Argument of type 'string | number | symbol' is not... Remove this comment to see the full error message
      (k, v) => [k, `${toQueryParamKey(k)}=${v}`],
    ),
  ).join('&');
  return isEmpty(search) ? '' : `?${search}`;
};

class PostSearchInputContainer extends Component<Props, State> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    query: this.props.query || '',

    prevProps: {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      query: this.props.query,
    },
  };

  onSearch = (props: { homePageUrl: string; query?: string }) => {
    const { homePageUrl, ...queryProps } = props;
    const queryString = makeQueryString(queryProps);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    this.props.navigate(`/${homePageUrl}${queryString}`);
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const updatedState = ['query'].reduce((acc, prop) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (props[prop] !== state.prevProps[prop]) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // eslint-disable-next-line no-param-reassign
        acc[prop] = props[prop];
      }

      return acc;
    }, {});

    if (Object.keys(updatedState).length > 0) {
      // @ts-expect-error TS(2339): Property 'prevProps' does not exist on type '{}'.
      updatedState.prevProps = {
        query: props.query,
      };
      return updatedState;
    }

    return null;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setQuery = (query: string, callback?: (...args: Array<any>) => any) => {
    // Algolia has a max query length of 512 bytes
    this.setState(
      {
        query: truncateToUTF8Bytes(query, 512),
      },
      callback,
    );
  };

  search = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    this.props.resetSearchFilters();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.query !== this.state.query) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      this.props.setSearchQuery(this.state.query, true);
    }

    const { query } = this.state;
    this.onSearch({
      query,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      homePageUrl: this.props.homePageUrl,
    });
  };

  render() {
    const { query } = this.state;
    const { searchPlaceholderText, usageContext } = this.props;
    const { setQuery, search } = this;
    return (
      <PostSearchInput
        id="post-search-input"
        usageContext={usageContext}
        data-qa-id="post-search-input"
        query={query}
        search={search}
        placeholder={searchPlaceholderText || getText('Search articles')}
        setQuery={setQuery}
        clearQuery={() => {
          setQuery('', search);
        }}
      />
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = ({ postSearch: { query } }: any) => ({
  query,
});

// eslint-disable-next-line import/no-default-export
export default withRouter(
  connect(mapStateToProps, {
    resetSearchFilters: resetPostSearchFilters,
    setSearchQuery: setPostSearchQuery,
    // @ts-expect-error TS(2345): Argument of type 'typeof PostSearchInputContainer'... Remove this comment to see the full error message
  })(PostSearchInputContainer),
);
