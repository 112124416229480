import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Form } from 'components/Form/Form';
import { FormSubmitFunction } from 'components/Form/FormContext';
import { ClearButton } from 'components/Input/ClearButton';
import { Input } from 'components/Input/Input';
import { cssBreakpoints } from 'theme/theme';

import { PostSearchButton } from './PostSearchButton';
import { PostSearchFormUsageContext } from './PostSearchFormUsageContext';

type Props = {
  id: string;
  query: string;
  placeholder?: string;
  usageContext: PostSearchFormUsageContext;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setQuery: (arg0: any) => void;
  clearQuery: () => void;
  search: () => void;
  'data-qa-id'?: string;
};

const FormContainer = styled.div`
  flex: 4;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    flex: 1;
  }
`;

const FormWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  gap: 11px;
  width: 100%;
`;

export function PostSearchInput({
  id,
  query,
  placeholder,
  setQuery,
  clearQuery,
  search,
  usageContext,
  'data-qa-id': qaId,
}: Props) {
  const [inputDisplay, setInputDisplay] = useState(query);
  const inputRef = useRef(null);
  useEffect(() => {
    if (query === '') {
      setInputDisplay('');
    }
  }, [query]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setInputDisplay(e.target.value);
  };

  const onClickClear = (e: MouseEvent) => {
    e.stopPropagation();
    clearQuery();
    setInputDisplay('');

    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (inputRef && inputRef.current) {
      // @ts-expect-error TS(2339): Property 'focus' does not exist on type 'never'.
      inputRef.current.focus();
    }
  };

  const onSubmit: FormSubmitFunction<Record<string, never>> = ({
    updateSubmitting,
  }) => {
    search();
    updateSubmitting(false);
  };

  const iconOnly = usageContext === 'searchResults';

  return (
    <FormContainer>
      <Form
        analyticsTitle="Post Search Form"
        usageContext="page"
        onSubmit={onSubmit}
        render={() => (
          <FormWrapper>
            <Input
              fullHeight
              fullWidth
              inputProps={{
                id,
                placeholder,
                ariaLabel: placeholder,
                ref: inputRef,
                value: inputDisplay,
                qaId,
                onChange,
              }}
              affixVariant="transparent"
              suffix={
                <ClearButton
                  onClear={onClickClear}
                  qaId="post-search-input-clear"
                />
              }
              hideSuffixWhenEmpty
            />
            <PostSearchButton qaId="post-search-button" iconOnly={iconOnly} />
          </FormWrapper>
        )}
      />
    </FormContainer>
  );
}
